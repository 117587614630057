body {
  margin: 0;
  font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.arrow-0 {
  animation-delay: 0s;
}

.arrow-1 {
  animation-delay: 0.3s;
}

.arrow-2 {
  animation-delay: 0.6s;
}

.arrow-3 {
  animation-delay: 0.9s;
}

.arrow-4 {
  animation-delay: 1.2s;
}

.arrow-animation {
  animation-fill-mode: both;
}

@tailwind base;
@tailwind components;
@tailwind utilities;